@import "variables";

$fleetAdvise-primary: #6200ed;
$fleetAdvise-color: #6200ed;
$fleetAdvise-hovar-color: #230055;

$primary-color: $fleetAdvise-primary;
$primary-color-light: #f2efff;
$primary-color-gradient-start: $fleetAdvise-primary;
$primary-color-gradient-end: #6200ed;
$primary-color-hover: #230055;
$light-blue: $fleetAdvise-color;
$primary-button-color: $fleetAdvise-color;
$primary-button-hover-color: $fleetAdvise-hovar-color;
$header-text-color: $fleetAdvise-primary;
$primary-button-color: $fleetAdvise-color;
$primary-button-hover-color: $fleetAdvise-hovar-color;
$secondary-button-text-color: $fleetAdvise-color;
$recharts-primary-color: $fleetAdvise-color;
$recharts-secondary-color: $fleetAdvise-color;
$secondary-color: $fleetAdvise-color;

.privacy-policy-footer {
  order: 1;
  flex: 1 0 100%;
  footer {
    bottom: 0;
    background: #f8f8f8;
    width: auto;
    max-width: inherit;
    overflow-x: hidden;
  }

  .info .legal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 500;
  }

  .info .legal > a {
    margin-left: 10px;
  }

  a[target="_blank"]::after {
    content: " ";
  }

  .info {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f8f8f8;
    color: #777777;
  }

  @media screen and (max-width: 600px) {
    .info {
      justify-content: center;
      flex-direction: column;
    }

    .info > .copyright {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 380px) {
    .info .legal {
      justify-content: center;
    }

    .info .legal a {
      margin: 0;
      text-align: center;
    }
  }
}
